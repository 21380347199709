import React, {useEffect, useState} from "react";
import {Autocomplete} from "@mui/material";
import SearchInput from "./SearchInput";
import ResultLink from "./ResultLink";
import {
  useAugmentToWithGlobalSearchParams,
  useNavigate,
} from "../../../routing";
import {useGlobalState} from "../../../global-config/GlobalConfig";
import {GTMEvents} from "../../../dataConstants";
import {
  getAccount,
  getAccountResources,
  getTransaction,
  getBlockByHeight,
  getBlockByVersion,
} from "../../../api";
import {sendToGTM} from "../../../api/hooks/useGoogleTagManager";
import {objectCoreAddress} from "../../../constants";
import {
  isValidAccountAddress,
  isValidTxnHashOrVersion,
  isNumeric,
  truncateAddress,
} from "../../utils";
import { getMoveHA } from "../../../api/query-utils";

export type SearchResult = {
  label0: string | null;
  label: string;
  to: string | null;
};

export const NotFoundResult: SearchResult = {
  label0: null,
  label: "No Results",
  to: null,
};

type SearchMode = "idle" | "typing" | "loading" | "results";

export default function HeaderSearch() {
  const navigate = useNavigate();
  const [state] = useGlobalState();
  const [mode, setMode] = useState<SearchMode>("idle");
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SearchResult | null>(
    null,
  );
  const augmentToWithGlobalSearchParams = useAugmentToWithGlobalSearchParams();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (mode !== "loading" && inputValue.trim().length > 0) {
      timer = setTimeout(() => {
        getMoveha(inputValue.trim());
        // const str = inputValue.trim();
        // fetchData({old:str,newstr:str});
      }, 500);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const fetchData = async (input:{old:string,newstr:string}) => {
    const {old, newstr} = input;
    const isSame = old === newstr;

    const searchText = newstr;

    setMode("loading");
    const searchPerformanceStart = GTMEvents.SEARCH_STATS + " start";
    const searchPerformanceEnd = GTMEvents.SEARCH_STATS + " end";
    window.performance.mark(searchPerformanceStart);

    const isValidAccountAddr = isValidAccountAddress(searchText);
    const isValidTxnHashOrVer = isValidTxnHashOrVersion(searchText);
    const isValidBlockHeightOrVer = isNumeric(searchText);

    const promises = [];

    const isAnsName =
      searchText.endsWith(".apt") || searchText.endsWith(".petra");

    if (isAnsName) {
      try {
        const name = await state.sdk_v2_client?.getName({
          name: searchText,
        });
        const address = name?.registered_address;
        const primaryName = await state.sdk_v2_client?.getPrimaryName({
          address: name?.owner_address ?? "",
        });

        if (!primaryName || !name || !address) {
          throw new Error("Primary name not found");
        }

        promises.push({
          label: `Account ${truncateAddress(address)}${
            primaryName ? ` | ${primaryName}.apt` : ``
          }`,
          to: `/account/${name.owner_address}`,
        });
      } catch (e) {}
    } else {
      if (isValidAccountAddr) {
        // It's either an account OR an object: we query both at once to save time
        const accountPromise = await getAccount(
          {address: searchText},
          state.network_value,
        )
          .then((): SearchResult => {
            if(isSame){
              return {
                label0:null,
                label: `Account ${searchText}`,
                to: `/account/${searchText}`,
              };
            }else{
              return {
                label0: `Account (MEVM) ${old}`,
                label: `Account (MOVE)  ${searchText}`,
                to: `/account/${searchText}`,
              };
            }
           
          })
          .catch(() => {
            return null;
            // Do nothing. It's expected that not all search input is a valid account
          });

        const resourcePromise = await getAccountResources(
          {address: searchText},
          state.network_value,
        )
          .then((resources): SearchResult | undefined => {
            let hasObjectCore = false;
            resources.forEach((resource) => {
              if (resource.type === objectCoreAddress) {
                hasObjectCore = true;
              }
            });
            if (hasObjectCore) {
              return {
                label0: null,
                label: `Object ${searchText}`,
                to: `/object/${searchText}`,
              };
            }
          })
          .catch(() => {
            return null;
            // Do nothing. It's expected that not all search input is a valid account
          });
        promises.push(accountPromise);
        promises.push(resourcePromise);
      }

      if (isValidTxnHashOrVer) {
        const txnPromise = getTransaction(
          {txnHashOrVersion: searchText},
          state.network_value,
        )
          .then((): SearchResult => {
            if(isSame){
              return {
                label0:null,
                label: `Transaction ${searchText}`,
                to: `/txn/${searchText}`,
              };
            }else{
              return {
                label: `Transaction (MOVE) ${searchText}`,
                to: `/txn/${searchText}`,
                label0: `Transaction (MEVM) ${old}`,
              };
            }
           
          })
          .catch(() => {
            return null;
            // Do nothing. It's expected that not all search input is a valid transaction
          });
        promises.push(txnPromise);
      }

      if (isValidBlockHeightOrVer) {
        const blockByHeightPromise = getBlockByHeight(
          {height: parseInt(searchText), withTransactions: false},
          state.network_value,
        )
          .then((): SearchResult => {
            return {
              label0: null,
              label: `Block ${searchText}`,
              to: `/block/${searchText}`,
            };
          })
          .catch(() => {
            return null;
            // Do nothing. It's expected that not all search input is a valid transaction
          });

        const blockByVersionPromise = getBlockByVersion(
          {version: parseInt(searchText), withTransactions: false},
          state.network_value,
        )
          .then((block): SearchResult => {
            return {
              label0: null,
              label: `Block with Txn Version ${searchText}`,
              to: `/block/${block.block_height}`,
            };
          })
          .catch(() => {
            return null;
            // Do nothing. It's expected that not all search input is a valid transaction
          });
        promises.push(blockByHeightPromise);
        promises.push(blockByVersionPromise);
      }
    }

    const resultsList = await Promise.all(promises);
    const results = resultsList
      .filter((result): result is SearchResult => !!result)
      .map((result) => {
        if (result.to) {
          return {...result, to: augmentToWithGlobalSearchParams(result.to)};
        }

        return result;
      });

    window.performance.mark(searchPerformanceEnd);
    sendToGTM({
      dataLayer: {
        event: GTMEvents.SEARCH_STATS,
        network: state.network_name,
        searchText: searchText,
        searchResult: results.length === 0 ? "notFound" : "success",
        duration: window.performance.measure(
          GTMEvents.SEARCH_STATS,
          searchPerformanceStart,
          searchPerformanceEnd,
        ).duration,
      },
    });

    if (results.length === 0) {
      results.push(NotFoundResult);
    }

    setOptions(results);
    setMode("idle");
    setOpen(true);
  };

  const getMoveha= (address:string)=>{
    const str = address.trim();
    if(str.length === 0){
      return;
    }

    // if(str.length === 42){   //check if address is valid
    //   getMoveHA('debug_getMoveAddress',str).then((res:any)=>{
    //     fetchData({old:str,newstr:res.data});
    //   });
    // }else 
    if(str.length === 66){   //check if hash is valid
      getMoveHA('debug_getMoveHash',str).then((res:any)=>{

        // console.log('res',res);
        fetchData({old:str,newstr:res.data});
      });
    }else{
      fetchData({old:str,newstr:str});
    }
  };

  return (
    <Autocomplete
      open={open}
      sx={{
        mb: {sm: 1, md: 2},
        flexGrow: 1,
        width: "100%",
        "&.MuiAutocomplete-root .MuiFilledInput-root": {
          py: 1.5,
          px: 2,
        },
        "&.MuiAutocomplete-root .MuiFormHelperText-root": {
          opacity: "0",
          mt: 0.5,
          mb: 0,
          fontFamily: "apparat",
          fontWeight: "light",
        },
        "&.Mui-focused .MuiFormHelperText-root": {
          opacity: "0.6",
        },
      }}
      autoHighlight
      handleHomeEndKeys
      forcePopupIcon={false}
      selectOnFocus={true}
      freeSolo
      clearOnBlur
      autoSelect={false}
      getOptionLabel={() => ""}
      filterOptions={(x) => x.filter((x) => !!x)}
      options={options}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        setOpen(false);
        if (event && event.type === "blur") {
          setInputValue("");
        } else if (reason !== "reset") {
          setMode(newInputValue.trim().length === 0 ? "idle" : "typing");
          setInputValue(newInputValue);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          const selected = selectedOption?.to ?? options[0]?.to;
          if (selected) {
            navigate(selected);
          }
          event.preventDefault();
        }
      }}
      onClose={() => setOpen(false)}
      renderInput={(params) => {
        return (
          <SearchInput
            {...params}
            loading={mode === "loading" || mode === "typing"}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={props.id}>
            <ResultLink to={option.to} text={option.label} text0={option.label0}  />
          </li>
        );
      }}
      onHighlightChange={(event, option) => {
        if (option !== null) {
          const optionCopy = Object.assign({}, option);
          setSelectedOption(optionCopy);
        }
      }}
    />
  );
}
